
























































import BaseButton from '@/components/base/BaseButton.vue';
import MemberPage from '@/layouts/MemberLayout/MemberPage.vue';
import { defineComponent, onMounted, reactive, ref } from '@/plugins/composition';
import { routeNames } from '@/router/routeNames';
import { rules } from '@/composables/useValidation/validations';
import { useLoader } from '@/composables/useLoader';
import { useApi } from '@/composables/useApi';
import { snakeKeys } from '@/utils/case';
import { useAuthStore } from '@/composables/useAuthStore';
import { useFormValidation } from '@/composables/useValidation';

export default defineComponent({
  components: { MemberPage, BaseButton },
  name: 'ContactPage',
  setup() {
    const valid = ref(false);
    const success = ref(false);
    const { loading, withLoader } = useLoader();
    const { post } = useApi();
    const { isAuthed, user } = useAuthStore();
    const formData = reactive({
      email: '',
      name: '',
      message: '',
    });
    const { errors, mapErrors } = useFormValidation(formData);

    onMounted(() => {
      if (isAuthed.value) {
        formData.email = user.value.data.email;
        formData.name = user.value.data.firstName + ' ' + user.value.data.lastName;
      }
    });

    const submit = async () =>
      withLoader(async () => {
        await post('/contact', snakeKeys(formData))
          .catch((errors) => mapErrors(errors))
          .then(() => {
            success.value = true;
          });
      });

    return {
      routeNames,
      formData,
      valid,
      rules,
      success,
      loading,
      submit,
      errors,
    };
  },
});
